import React, { Component } from "react";
import { Button, CardContent, CardHeader, Form, Message, Container, Segment, Grid, Card, Image } from "semantic-ui-react";
import { Link } from "gatsby";
import { EmailField, UsernameEmailField } from "./form-fields";
import KaisakuUserApi from "../../controllers/kaisaku-user-api";
import withTrans from "../../i18n/withTrans";
import icon from "../../images/report.png";
import KaisakuApi from "../../controllers/kaisaku-api";
import AndroidComm from "../../controllers/utilities/unity-android-communication";
import { formatQuery } from "../../controllers/utilities/string-utils";
import { navigate } from "../../../.cache/gatsby-browser-entry";
import divbox from "../../images/manage/divbox.png";
import showapp from "../../images/manage/downloadapp.png";
import showapp2 from "../../images/manage/downloadappfreegold.png";
import showemail from "../../images/manage/bindemail.png";
import showemail2 from "../../images/manage/bindemailfreegold.png";

import modifypwd from "../../images/manage/modifypwd.png";
import onlinecs from "../../images/manage/onlinecs.png";
import cardinfo from "../../images/manage/cardinfo.png";
import teaching from "../../images/manage/teaching.png";
import discord from "../../images/manage/discord.png";
import unionaaa from "../../images/manage/unionaaa.png";
import shopbbb from "../../images/manage/shopbtn.png";
import cookie from "react-cookies";

class UserDetailForm extends Component {
    constructor(props, context) {
        super(props, context);
        let accessToken = "";
        if (this.props.session) accessToken = this.props.session.accessToken;
        this.state = {
            username: "",
            inGameId: this.props.ingameId,
            email: "",
            applogin: "",
            accessToken: accessToken,
            errorMsgs: [],
            showReturnBtn: this.props.showReturnBtn,
            loading: false,
            ready: false,
            hasEmail: false,
            success: false
        };
    }

    async componentDidMount() {
        this.setState({
            loading: true
        });
        const res = await new KaisakuUserApi(this.props.session).getProfileApp();
        console.log(res);
        if (res.status === 200) {
            this.profile = res.data;
            this.setState({
                loading: false,
                ready: true,
                username: this.profile.username,
                email: this.profile.email,
                hasEmail: !!this.profile.email,
                applogin: this.profile.applogin
            });
        } else {
            console.error("Error getting user profile!");
        }
    }

    render() {
        // console.log(this.props.gameId);
        return (
            <div fluid >
                <div>
                    
                            {(this.props.mode === "PORTRAIT") && (<>
                                <div fluid style={{
                    backgroundColor: "transparent",
                    backgroundImage: `url(${divbox})`, backgroundSize: '100% 100%',
                    margin: "-1rem",
                    padding: "2rem",
                    minHeight: "60vh"
                }}>
                    <Form size='large' loading={this.state.loading} success={this.state.success}
                        error={this.state.errorMsgs.length > 0}>
                        <CardContent style={{

                        }}></CardContent>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '80px', textAlign: 'left' }}><b>帳號:</b></td>
                                            <td style={{ textAlign: "right" }}>{this.state.username ? this.state.username : ""}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '80px', textAlign: 'left' }}><b>電子郵箱:</b></td>
                                            <td style={{ textAlign: "right" }}>{this.state.email ? this.state.email : ""}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <Image style={{width:"100%",
                                        backgroundColor: "#FFF",
                        }} src={unionaaa} onClick={() => {
                            // 保存返回地址
                            navigate('/unionpay-page1?re=1');
                        }}/>


{/* <Card fluid
                                    style={{
                                        height: "6em",
                                        width: "100%",
                                        "margin": 0,
                                        backgroundColor: "transparent",
                                        boxShadow: '0 0px 0px 0 transparent, 0 0 0 0px transparent',
                                    }}
                                    >
                                    <Image style={{
                                        // width: "100%",
                                        height:"100%",
                                        backgroundColor: "#FFF",
                                    }} src={shopbbb} onClick={() => {
                                        // 保存返回地址
                                        navigate(`/xr-saleing-page1?mode=T&accessToken=${this.state.accessToken}`);
                                    }}/>
                                    <div style={{
                                        position: 'absolute',
                                        top: "35%",
                                        left: "10%",
                                        width: "90%",
                                        textAlign: "center",
                                        fontSize: "1.3rem",
                                        fontWeight: "bold",
                                        zIndex: "1",
                                        color: "#FFF",
                                    }} onClick={() => {
                                        // 保存返回地址
                                        navigate(`/xr-saleing-page1?mode=T&accessToken=${this.state.accessToken}`);
                                    }}>
                                        實體商店
                                    </div>
                                    
                                </Card> */}


                                <Image style={{width:"100%",
                                        backgroundColor: "#FFF",
                        }} src={(this.state.applogin === "1") ? showapp : showapp2} onClick={() => {
                            window.location.href = 'https://e0aaqfo398.execute-api.ap-southeast-1.amazonaws.com/prod/getApkUrl302?gameId=2c5bb6a0-4ef0-619f-bb7d-9a0ef31b9ca4&r=download';
                        }}/>
                                {/* <Button fluid size='large' style={{
                                    height: "4.5em",
                                    backgroundColor: "transparent",
                                    backgroundImage: `url(${(this.state.applogin === "1") ? showapp : showapp2})`, backgroundSize: '100% 100%',
                                }}
                                    onClick={() => {
                                        window.location.href = 'https://e0aaqfo398.execute-api.ap-southeast-1.amazonaws.com/prod/getApkUrl302?gameId=2c5bb6a0-4ef0-619f-bb7d-9a0ef31b9ca4&r=download';
                                    }} >
                                    &nbsp;
                                </Button> */}





<Image style={{width:"100%"
                        }} src={(this.state.hasEmail) ? showemail : showemail2} 
                        // as={Link}
                        // to={'/attach-email'}
                        onClick={() => {
                            navigate('/attach-email');
                        }}
                        />

                                {/* <Button fluid size='large' style={{
                                    height: "4.5em",
                                    backgroundColor: "transparent",
                                    backgroundImage: `url(${(this.state.hasEmail) ? showemail : showemail2})`, backgroundSize: '100% 100%',
                                }}
                                    as={Link}
                                    to={'/attach-email'}>
                                    &nbsp; */}
                                    {/* {!this.state.hasEmail ? "綁定郵箱（即送25幣）" : "綁定郵箱地址"} */}
                                {/* </Button> */}

                                <Image style={{width:"100%",
                                        backgroundColor: "#FFF",
                        }} src={teaching} 
                        // as={Link}
                        // to={'/sdk-teachingsdkcallback'}
                        onClick={() => {
                            navigate('/sdk-teachingsdkcallback');
                        }}
                        />

                                {/* <Button fluid size='large' style={{
                                    height: "4.5em",
                                    backgroundColor: "transparent",
                                    backgroundImage: `url(${teaching})`, backgroundSize: '100% 100%',
                                }}
                                    as={Link}
                                    to={'/sdk-teachingsdkcallback'}>&nbsp;
                                </Button> */}

                                {this.state.hasEmail ?
                                <Image style={{width:"100%",
                                backgroundColor: "#FFF",
                            }} src={modifypwd} 
                            // as={Link}
                            // to={'/reset-password1?back=sdk'}
                            onClick={() => {
                                navigate('/reset-password1?back=sdk');
                            }}
                            />
                                    // <Button
                                    //     fluid size='large' style={{
                                    //         height: "4.5em",
                                    //         backgroundColor: "transparent",
                                    //         backgroundImage: `url(${modifypwd})`, backgroundSize: '100% 100%',
                                    //     }}
                                    //     as={Link}
                                    //     to={'/reset-password1?back=sdk'}>
                                    //     &nbsp;
                                    // </Button>
                                    :
                                    <Image style={{width:"100%",
                                    backgroundColor: "#FFF",
                            }} src={modifypwd} 
                            // as={Link}
                            // to={`/attach-email?message=${encodeURI('如要修改密碼，請先綁定電郵')}`}
                            onClick={() => {
                                navigate(`/attach-email?message=${encodeURI('如要修改密碼，請先綁定電郵')}`);
                            }}
                            />
                                    // <Button fluid size='large' style={{
                                    //     height: "4.5em",
                                    //     backgroundColor: "transparent",
                                    //     backgroundImage: `url(${modifypwd})`, backgroundSize: '100% 100%',
                                    // }}
                                    //     as={Link}
                                    //     to={`/attach-email?message=${encodeURI('如要修改密碼，請先綁定電郵')}`}>
                                    //     &nbsp;
                                    // </Button>
                                }

<Image style={{width:"100%"
                        }} src={onlinecs} onClick={() => {
                            if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
                                window.location.href = `/game-support/?transactionId=${this.props.transactionId}&r=newsafari`;
                            } else {
                                navigate(`/game-support/?transactionId=${this.props.transactionId}`);
                            }
                        }}/>

                                {/* <Button color='orange' fluid size='large' style={{
                                    height: "4.5em",
                                    backgroundColor: "transparent",
                                    backgroundImage: `url(${onlinecs})`, backgroundSize: '100% 100%',
                                }}
                                    onClick={() => {
                                        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
                                            window.location.href = `/game-support/?transactionId=${this.props.transactionId}&r=newsafari`;
                                        } else {
                                            navigate(`/game-support/?transactionId=${this.props.transactionId}`);
                                        }
                                    }}>
                                    &nbsp;
                                </Button> */}

<Image style={{width:"100%",
                                        backgroundColor: "#FFF",
                        }} src={discord} onClick={() => {
                            window.location.href = 'https://discord.gg/7JX5P32Q6B?r=download';
                        }}/>

                                {/* <Button fluid size='large' style={{
                                    height: "4.5em",
                                    backgroundColor: "transparent",
                                    backgroundImage: `url(${discord})`, backgroundSize: '100% 100%',
                                }}
                                    onClick={() => {
                                        window.location.href = 'https://discord.gg/7JX5P32Q6B?r=download';
                                    }} >
                                    &nbsp;
                                </Button> */}

<Image style={{width:"100%",
                                        backgroundColor: "#FFF",
                        }} src={cardinfo} 
                        // as={Link}
                        // to={'/myreportreturnsdkcallback'}
                        onClick={() => {
                            navigate('/myreportreturnsdkcallback');
                        }}
                        />

                                {/* <Button fluid size='large' style={{
                                    height: "4.5em",
                                    backgroundColor: "transparent",
                                    backgroundImage: `url(${cardinfo})`, backgroundSize: '100% 100%',
                                }}
                                    as={Link}
                                    to={'/myreportreturnsdkcallback'}>&nbsp;
                                </Button> */}
                                </Form>
                                </div>
                            </>

                            )}

                            {(this.props.mode === "LANDSCAPE") && (
                                <>
                                <Card fluid style={{
                    backgroundColor: "transparent",
                    boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent',
                    backgroundImage: `url(${divbox})`, backgroundSize: '100% 100%',
                    margin: "-1em",
                    padding: "5%",
                    minHeight: "80vh",
                }}>
                                <Form size='large' loading={this.state.loading} success={this.state.success}
                        error={this.state.errorMsgs.length > 0}>
                        
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '50%', textAlign: 'left', paddingLeft: "20px" }}><b>帳號:</b>{this.state.username ? this.state.username : ""}</td>
                                                <td style={{ width: '50%', textAlign: 'left', paddingLeft: "20px" }}><b>電子郵箱:</b>{this.state.email ? this.state.email : ""}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div style={{ padding: "10px" }}>
                                    <Card fluid
                                            style={{
                                                float: "left",
                                                width: "50%",
                                                "margin": 0,
                                                backgroundColor: "transparent",
                                                boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent',
                                            }}>
                                                <Image style={{width:"100%",
                                        backgroundColor: "#FFF",
                        }} src={unionaaa} onClick={() => {
                            // 保存返回地址
                            navigate('/unionpay-page1?re=1');
                        }}/>
                                            {/* <Button fluid size='large' style={{
                                                height: "20vh",
                                                backgroundColor: "transparent",
                                                backgroundImage: `url(${(this.state.applogin === "1") ? showapp : showapp2})`, backgroundSize: '100% 100%',
                                            }}
                                                onClick={() => {
                                                    window.location.href = 'https://e0aaqfo398.execute-api.ap-southeast-1.amazonaws.com/prod/getApkUrl302?gameId=2c5bb6a0-4ef0-619f-bb7d-9a0ef31b9ca4&r=download';
                                                }} >
                                                &nbsp;
                                            </Button> */}
                                        </Card>


                                        {/* <Card fluid
                                            style={{
                                                float: "left",
                                                width: "50%",
                                                "margin": 0,
                                                backgroundColor: "transparent",
                                                boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent',
                                            }} >
                                            <Image style={{
                                                width: "100%",
                                                backgroundColor: "#FFF",
                                            }} src={shopbbb} onClick={() => {
                                                // 保存返回地址
                                                navigate(`/xr-saleing-page1?mode=T&accessToken=${this.state.accessToken}`);
                                            }}/>
                                            <div style={{
                                                position: 'absolute',
                                                top: "35%",
                                                left: "10%",
                                                width: "90%",
                                                textAlign: "center",
                                                fontSize: "1.3rem",
                                                fontWeight: "bold",
                                                zIndex: "1",
                                                color: "#FFF",
                                            }} onClick={() => {
                                                // 保存返回地址
                                                navigate(`/xr-saleing-page1?mode=T&accessToken=${this.state.accessToken}`);
                                            }}>
                                                實體商店
                                            </div>
                                            
                                        </Card> */}

                                        <Card fluid
                                            style={{
                                                float: "left",
                                                width: "50%",
                                                "margin": 0,
                                                backgroundColor: "transparent",
                                                boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent',
                                            }}>
                                                <Image style={{width:"100%",
                                        backgroundColor: "#FFF",
                        }} src={(this.state.applogin === "1") ? showapp : showapp2} onClick={() => {
                            window.location.href = 'https://e0aaqfo398.execute-api.ap-southeast-1.amazonaws.com/prod/getApkUrl302?gameId=2c5bb6a0-4ef0-619f-bb7d-9a0ef31b9ca4&r=download';
                        }}/>
                                            {/* <Button fluid size='large' style={{
                                                height: "20vh",
                                                backgroundColor: "transparent",
                                                backgroundImage: `url(${(this.state.applogin === "1") ? showapp : showapp2})`, backgroundSize: '100% 100%',
                                            }}
                                                onClick={() => {
                                                    window.location.href = 'https://e0aaqfo398.execute-api.ap-southeast-1.amazonaws.com/prod/getApkUrl302?gameId=2c5bb6a0-4ef0-619f-bb7d-9a0ef31b9ca4&r=download';
                                                }} >
                                                &nbsp;
                                            </Button> */}
                                        </Card>

                                        <Card fluid
                                            style={{
                                                float: "left",
                                                width: "50%",
                                                "margin": 0,
                                                backgroundColor: "transparent",
                                                boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent'
                                            }}>
                                                <Image style={{width:"100%",
                                        backgroundColor: "#FFF",
                        }} src={onlinecs} onClick={() => {
                            if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
                                window.location.href = `/game-support/?transactionId=${this.props.transactionId}&r=newsafari`;
                            } else {
                                navigate(`/game-support/?transactionId=${this.props.transactionId}`);
                            }
                        }}/>

                                            {/* <Button color='orange' fluid size='large' style={{
                                                height: "20vh",
                                                backgroundColor: "transparent",
                                                backgroundImage: `url(${onlinecs})`, backgroundSize: '100% 100%',
                                            }}
                                                onClick={() => {
                                                    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
                                                        window.location.href = `/game-support/?transactionId=${this.props.transactionId}&r=newsafari`;
                                                    } else {
                                                        navigate(`/game-support/?transactionId=${this.props.transactionId}`);
                                                    }
                                                }}>
                                                &nbsp;
                                            </Button> */}
                                            </Card>

                                        <Card fluid
                                            style={{
                                                float: "left",
                                                width: "50%",
                                                "margin": 0,
                                                backgroundColor: "transparent",
                                                boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent'
                                            }}>
                                                <Image style={{width:"100%",
                                        backgroundColor: "#FFF",
                        }} src={(this.state.hasEmail) ? showemail : showemail2} 
                        // as={Link}
                        // to={'/attach-email'}
                        onClick={() => {
                            navigate('/attach-email');
                        }}
                        />

                                            {/* <Button fluid size='large' style={{
                                                height: "20vh",
                                                backgroundColor: "transparent",
                                                backgroundImage: `url(${(this.state.hasEmail) ? showemail : showemail2})`, backgroundSize: '100% 100%',
                                            }}
                                                as={Link}
                                                to={'/attach-email'}>
                                                &nbsp; */}
                                                {/* {!this.state.hasEmail ? "綁定郵箱（即送25幣）" : "綁定郵箱地址"} */}
                                            {/* </Button> */}
                                        </Card>

                                        <Card fluid
                                            style={{
                                                float: "left",
                                                width: "50%",
                                                "margin": 0,
                                                backgroundColor: "transparent",
                                                boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent'
                                            }}>
                                                <Image style={{width:"100%",
                                        backgroundColor: "#FFF",
                        }} src={discord} onClick={() => {
                            window.location.href = 'https://discord.gg/7JX5P32Q6B?r=download';
                        }}/>

                                            {/* <Button fluid size='large' style={{
                                                height: "20vh",
                                                backgroundColor: "transparent",
                                                backgroundImage: `url(${discord})`, backgroundSize: '100% 100%',
                                            }}
                                                onClick={() => {
                                                    window.location.href = 'https://discord.gg/7JX5P32Q6B?r=download';
                                                }} >
                                                &nbsp;
                                            </Button> */}
                                            </Card>


                                        <Card fluid
                                            style={{
                                                float: "left",
                                                width: "50%",
                                                "margin": 0,
                                                backgroundColor: "transparent",
                                                boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent'
                                            }}>
                                                <Image style={{width:"100%",
                                        backgroundColor: "#FFF",
                        }} src={teaching} 
                        // as={Link}
                        // to={'/sdk-teachingsdkcallback'}
                        onClick={() => {
                            navigate('/sdk-teachingsdkcallback');
                        }}
                        />

                                            {/* <Button fluid size='large' style={{
                                                height: "20vh",
                                                backgroundColor: "transparent",
                                                backgroundImage: `url(${teaching})`, backgroundSize: '100% 100%',
                                            }}
                                                as={Link}
                                                to={'/sdk-teachingsdkcallback'}>&nbsp;
                                            </Button> */}
                                        </Card>

                                        <Card fluid
                                            style={{
                                                float: "right",
                                                width: "50%",
                                                "margin": 0,
                                                backgroundColor: "transparent",
                                                boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent'
                                            }}>
                                                <Image style={{width:"100%",
                                        backgroundColor: "#FFF",
                        }} src={cardinfo} 
                        // as={Link}
                        // to={'/myreportreturnsdkcallback'}
                        onClick={() => {
                            navigate('/myreportreturnsdkcallback');
                        }}
                        />

                                            {/* <Button fluid size='large' style={{
                                                height: "20vh",
                                                backgroundColor: "transparent",
                                                backgroundImage: `url(${cardinfo})`, backgroundSize: '100% 100%',
                                            }}
                                                as={Link}
                                                to={'/myreportreturnsdkcallback'}>&nbsp;
                                            </Button> */}
                                            </Card>

                                        {this.state.hasEmail ?
                                            <Card fluid
                                                style={{
                                                    float: "left",
                                                    width: "50%",
                                                    "margin": 0,
                                                    backgroundColor: "transparent",
                                                    boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent'
                                                }}>
                                                    <Image style={{width:"100%",
                                        backgroundColor: "#FFF",
                            }} src={modifypwd} 
                            // as={Link}
                            // to={'/reset-password1?back=sdk'}
                            onClick={() => {
                                navigate('/reset-password1?back=sdk');
                            }}
                            />
                                                    {/* <Button
                                                    fluid size='large' style={{
                                                        height: "20vh",
                                                        backgroundColor: "transparent",
                                                        backgroundImage: `url(${modifypwd})`, backgroundSize: '100% 100%',
                                                    }}
                                                    as={Link}
                                                    to={'/reset-password1?back=sdk'}>
                                                    &nbsp;
                                                </Button> */}
                                                </Card>
                                            :
                                            <Card fluid
                                                style={{
                                                    float: "left",
                                                    width: "50%",
                                                    "margin": 0,
                                                    backgroundColor: "transparent",
                                                    boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent'
                                                }}>
                                                    <Image style={{width:"100%",
                                        backgroundColor: "#FFF",
                            }} src={modifypwd} 
                            // as={Link}
                            // to={`/attach-email?message=${encodeURI('如要修改密碼，請先綁定電郵')}`}
                            onClick={() => {
                                navigate(`/attach-email?message=${encodeURI('如要修改密碼，請先綁定電郵')}`);
                            }}
                            />
                                                {/* <Button fluid size='large' style={{
                                                    height: "20vh",
                                                    backgroundColor: "transparent",
                                                    backgroundImage: `url(${modifypwd})`, backgroundSize: '100% 100%',
                                                }}
                                                    as={Link}
                                                    to={`/attach-email?message=${encodeURI('如要修改密碼，請先綁定電郵')}`}>
                                                    &nbsp;
                                                </Button> */}
                                                </Card>
                                        }
                                    </div>

                                    </Form>
                                    </Card>



                                </>
                            )}




                        
                                    </div>
                {/* {this.state.hasEmail ?
                    <Message>
                        已綁定郵箱，如要更改郵箱地址可重新綁定
                    </Message>
                    :
                    null
                } */}


            </div>
        );
    }
}

export default withTrans(UserDetailForm);