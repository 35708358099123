import React, { Component } from "react";
import { Grid, Image } from "semantic-ui-react";
import manageicon from "../../images/main/manageicon.png";
import btnback from "../../images/main/back.png";
import topbg from "../../images/main/vtopbar.png";
import htopbg from "../../images/main/htopbar.png";
import hlogo from "../../images/main/hlogo.png";
import vlogo from "../../images/main/vlogo.png";
import title1 from "../../images/manage/managetitle.png";
import title2 from "../../images/teaching/title.png";
import title3 from "../../images/manage/bindmailtitle.png";
import title4 from "../../images/login/setpwd.png";
import title5 from "../../images/login/createaccount.png";
import anniversary from "../../images/anniversary.png";
import Logo1 from "../../images/g1.png";
import Logo2 from "../../images/g2.png";
import Logo3 from "../../images/5monthlogo1.png";
import { AdLogoSwipetoslide } from "../react-slick/ad-logo-swipetoslide";
import cookie from "react-cookies";
import KaisakuApi from "../../controllers/kaisaku-api";

import { navigate } from "gatsby";

class NavigationBar extends Component {
    constructor(props, context) {
        super(props, context);

    }
    render() {
        const {
            mode,
            platform,
            showLogo,
            showTitle,
            onBack,
            accessToken,
            isCnIP
        } = this.props;
        const adItem1 = [
            {
                adImage: Logo1,
                pos: 1,
                id: 1,
                adUrl: "https://dok0bgyfz2ppp.cloudfront.net/5month/index.html?accessToken=" + accessToken,
                // adUrl: "http://www.baidu.com?accessToken=" + accessToken,
                inapp: 1,
                monthtopup: 1
            },
            //     {
            //     adImage: Logo2,
            //     pos:1,
            //     id:1,
            //     adUrl: "/xr-saleing-page1?mode=T&accessToken=" + accessToken,
            //     inapp: 1
            // }
        ];
        return (<>
            {(mode === "PORTRAIT") && (<div style={{
                width: '100%',
                height: "80px",
                position: "fixed",
                zIndex: "999999",
                top: "0",
                left: "0",
                backgroundImage: `url(${topbg})`, backgroundSize: '100% 100%'
            }}>
                <div style={{ width: "100%", textAlign: "center", marginTop: "2vh" }}>
                    <Image style={{
                        marginBottom: "2vh",
                        height: "3vh",
                        position: "absolute",
                        top: "3vh",
                        left: "3vw"
                    }}
                        onClick={onBack}
                        centered src={btnback} />
                </div>

                {(showTitle === 0) && (<Image style={{
                    marginTop: "2.5vh",
                    height: "3vh",
                }}
                    centered src={vlogo} />)}

                {(showTitle === 1) && (<Image style={{
                    marginTop: "3vh",
                    marginBottom: "2vh",
                    width: "20vw"
                }} centered src={title1} />)}

                {(showTitle === 2) && (<Image style={{
                    marginTop: "3.4vh",
                    marginBottom: "2vh",
                    width: "20vw"
                }} centered src={title2} />)}

                {(showTitle === 3) && (<Image style={{
                    marginTop: "3.4vh",
                    marginBottom: "2vh",
                    width: "20vw"
                }} centered src={title3} />)}

                {(showTitle === 4) && (<Image style={{
                    marginTop: "3.4vh",
                    marginBottom: "2vh",
                    width: "20vw"
                }} centered src={title4} />)}

                {(showTitle === 5) && (<Image style={{
                    marginTop: "3.4vh",
                    marginBottom: "2vh",
                    width: "25vw"
                }} centered src={title5} />)}

                {(platform !== "h5") && (<div style={{
                    position: "absolute",
                    top: "0.8vh",
                    right: "11vw",
                    width: "16vw"
                }}>
                    {/* {(isCnIP === false) && (<Image style={{
                    }}
                        onClick={() => {
                            window.location.href = "https://2yearatt3.sugar1818.net?r=download&accesstoken=" + accessToken;
                        }}
                        centered src={anniversary} />)}
                        {(isCnIP === true) && (<AdLogoSwipetoslide adItems={adItem1} accessToken={accessToken} />)} */}
                    {/* <AdLogoSwipetoslide adItems={adItem1} accessToken={accessToken} /> */}
                    <Image style={{
                    }}
                        onClick={async () => {
                            // const Url = "https://dok0bgyfz2ppp.cloudfront.net/5month/index.html?accessToken=" + accessToken;                            
                            const Url = "/x-recharge-page1?accessToken=" + accessToken;                            
                            const inapp = 1;
                            const monthtopup = 0;
                            if (Url !== "") {
                                let newurl = Url;
                                let newinapp = inapp;
                                if (monthtopup === 1) {
                                    let newAccessToken = undefined;
                                    let newGameId = undefined;
                                    let newAttributionId = undefined;
                                    let pendingTransactionId = undefined;

                                    if (pendingTransactionId === undefined) {
                                        newAccessToken = cookie.load("_accessToken");
                                        newGameId = cookie.load("_gameId");
                                        newAttributionId = cookie.load("_attributionId");
                                        pendingTransactionId = cookie.load("_transactionId");
                                    }
                                    let platform = cookie.load("_platform");
                                    const saveurl = window.location.origin + `/confirm-purchase/?gameId=${newGameId}&attributionId=${newAttributionId}&transactionId=${pendingTransactionId}&accessToken=${newAccessToken}&platform=${platform}`;
                                    // console.log(saveurl);
                                    const data = await KaisakuApi.setUrlToTemp(saveurl, newAccessToken);
                                    if (data.status === 200) {
                                        newurl = newurl.replace(RegExp("@newaccessToken@", "g"), newAccessToken);
                                        newurl = newurl.replace(RegExp("download", "g"), "") + "&code=" + data.data.code;
                                        // console.log(newurl);
                                        // alert(url);
                                        window.location.href = newurl;
                                    }
                                } else {
                                    if (inapp === 1) {
                                        navigate(newurl);
                                    } else {
                                        window.location.href = newurl;
                                    }
                                }
                            }
                        }}
                        centered src={Logo1} />
                </div>)}


                {(platform !== "h5") && (<div style={{ width: "100%", textAlign: "center", marginTop: "2vh" }}>
                    <Image style={{
                        marginBottom: "2vh",
                        height: "3vh",
                        position: "absolute",
                        top: "3vh",
                        right: "3vw"
                    }}
                        onClick={() => {
                            navigate("/sdk-callback");
                        }}
                        centered src={manageicon} />
                </div>)}
            </div>)}
            {(mode === "LANDSCAPE") && (<div style={{
                width: '100%',
                height: "80px",
                position: "fixed",
                zIndex: "999999",
                top: "0",
                left: "0",
                backgroundImage: `url(${htopbg})`, backgroundSize: '100% 100%',
            }}>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <Image style={{
                        marginBottom: "2vh",
                        height: "30px",
                        position: "absolute",
                        top: "30px",
                        left: "1.5vw"
                    }}
                        onClick={onBack}
                        centered src={btnback} />
                </div>
                <Image style={{
                    marginTop: "10px",
                    height: "40px",
                }}
                    centered src={hlogo} />

                {(showTitle === 1) && (<Image style={{
                    marginTop: "0px",
                    height: "26px"
                }} centered src={title1} />)}

                {(showTitle === 2) && (<Image style={{
                    marginTop: "3px",
                    height: "18px"
                }} centered src={title2} />)}

                {(showTitle === 3) && (<Image style={{
                    marginTop: "3px",
                    height: "18px"
                }} centered src={title3} />)}

                {(showTitle === 4) && (<Image style={{
                    marginTop: "3px",
                    height: "18px"
                }} centered src={title4} />)}

                {(showTitle === 5) && (<Image style={{
                    marginTop: "3px",
                    height: "18px"
                }} centered src={title5} />)}

                {(platform !== "h5") && (<div style={{
                    position: "absolute",
                    top: "0.8vh",
                    right: "8vw",
                    width: "8vw"
                }}>
                    {/* {(isCnIP === false) && (<Image style={{
                    }}
                        onClick={() => {
                            window.location.href = "https://2yearatt3.sugar1818.net?r=download&accesstoken=" + accessToken;
                        }}
                        centered src={anniversary} />)}
                        {(isCnIP === true) && (<AdLogoSwipetoslide adItems={adItem1} accessToken={accessToken} />)} */}
                    {/* <AdLogoSwipetoslide adItems={adItem1} accessToken={accessToken} /> */}
                    <Image style={{
                    }}
                        onClick={async () => {
                            // const Url = "https://dok0bgyfz2ppp.cloudfront.net/5month/index.html?accessToken=" + accessToken;
                            const Url = "/x-recharge-page1?accessToken=" + accessToken;
                            const inapp = 1;
                            const monthtopup = 0;
                            if (Url !== "") {
                                let newurl = Url;
                                let newinapp = inapp;
                                if (monthtopup === 1) {
                                    let newAccessToken = undefined;
                                    let newGameId = undefined;
                                    let newAttributionId = undefined;
                                    let pendingTransactionId = undefined;

                                    if (pendingTransactionId === undefined) {
                                        newAccessToken = cookie.load("_accessToken");
                                        newGameId = cookie.load("_gameId");
                                        newAttributionId = cookie.load("_attributionId");
                                        pendingTransactionId = cookie.load("_transactionId");
                                    }
                                    let platform = cookie.load("_platform");
                                    const saveurl = window.location.origin + `/confirm-purchase/?gameId=${newGameId}&attributionId=${newAttributionId}&transactionId=${pendingTransactionId}&accessToken=${newAccessToken}&platform=${platform}`;
                                    // console.log(saveurl);
                                    const data = await KaisakuApi.setUrlToTemp(saveurl, newAccessToken);
                                    if (data.status === 200) {
                                        newurl = newurl.replace(RegExp("@newaccessToken@", "g"), newAccessToken);
                                        newurl = newurl.replace(RegExp("download", "g"), "") + "&code=" + data.data.code;
                                        // console.log(newurl);
                                        // alert(url);
                                        window.location.href = newurl;
                                    }
                                } else {
                                    if (inapp === 1) {
                                        navigate(newurl);
                                    } else {
                                        window.location.href = newurl;
                                    }
                                }
                            }
                        }}
                        centered src={Logo1} />
                </div>)}

                {(platform !== "h5") && (<div style={{ width: "100%", textAlign: "center" }}>
                    <Image style={{
                        marginBottom: "2vh",
                        height: "30px",
                        position: "absolute",
                        top: "30px",
                        right: "1.5vw"
                    }}
                        onClick={() => {
                            navigate("/sdk-callback");
                        }}
                        centered src={manageicon} />
                </div>)}
            </div>)}
        </>)
    };

}

class NavigationBar2 extends Component {
    constructor(props, context) {
        super(props, context);

    }
    render() {
        const {
            mode,
            platform,
            showLogo,
            showTitle,
            onBack,
            accessToken,
            isCnIP
        } = this.props;
        const adItem1 = [
            {
                adImage: Logo3,
                pos: 1,
                id: 1,
                adUrl: "https://dok0bgyfz2ppp.cloudfront.net/5month/index.html?accessToken=" + accessToken,
                // adUrl: "http://www.baidu.com?accessToken=" + accessToken,
                inapp: 1,
                monthtopup: 1
            },
            //     {
            //     adImage: Logo2,
            //     pos:1,
            //     id:1,
            //     adUrl: "/xr-saleing-page1?mode=T&accessToken=" + accessToken,
            //     inapp: 1
            // }
        ];
        return (<>
            {(mode === "PORTRAIT") && (<div style={{
                width: '100%',
                height: "80px",
                position: "fixed",
                zIndex: "999999",
                top: "0",
                left: "0",
                backgroundImage: `url(${topbg})`, backgroundSize: '100% 100%'
            }}>
                <div style={{ width: "100%", textAlign: "center", marginTop: "2vh" }}>
                    <Image style={{
                        marginBottom: "2vh",
                        height: "3vh",
                        position: "absolute",
                        top: "3vh",
                        left: "3vw"
                    }}
                        onClick={onBack}
                        centered src={btnback} />
                </div>

                {(showTitle === 0) && (<Image style={{
                    marginTop: "2.5vh",
                    height: "3vh",
                }}
                    centered src={vlogo} />)}

                {(showTitle === 1) && (<Image style={{
                    marginTop: "3vh",
                    marginBottom: "2vh",
                    width: "20vw"
                }} centered src={title1} />)}

                {(showTitle === 2) && (<Image style={{
                    marginTop: "3.4vh",
                    marginBottom: "2vh",
                    width: "20vw"
                }} centered src={title2} />)}

                {(showTitle === 3) && (<Image style={{
                    marginTop: "3.4vh",
                    marginBottom: "2vh",
                    width: "20vw"
                }} centered src={title3} />)}

                {(showTitle === 4) && (<Image style={{
                    marginTop: "3.4vh",
                    marginBottom: "2vh",
                    width: "20vw"
                }} centered src={title4} />)}

                {(showTitle === 5) && (<Image style={{
                    marginTop: "3.4vh",
                    marginBottom: "2vh",
                    width: "25vw"
                }} centered src={title5} />)}

                {(platform !== "h5") && (<div style={{
                    position: "absolute",
                    top: "0.8vh",
                    right: "11vw",
                    width: "16vw"
                }}>
                    {/* {(isCnIP === false) && (<Image style={{
                    }}
                        onClick={() => {
                            window.location.href = "https://2yearatt3.sugar1818.net?r=download&accesstoken=" + accessToken;
                        }}
                        centered src={anniversary} />)}
                        {(isCnIP === true) && (<AdLogoSwipetoslide adItems={adItem1} accessToken={accessToken} />)} */}
                    {/* <AdLogoSwipetoslide adItems={adItem1} accessToken={accessToken} /> */}
                    <Image style={{
                    }}
                        onClick={async () => {
                            let Url = "https://d2p58o8lf64bbz.cloudfront.net/5month/index.html?accessToken=" + accessToken;
                            const Stage = process.env.Stage;
                            if (Stage === "dev"){
                                Url = "https://dok0bgyfz2ppp.cloudfront.net/5month/index.html?accessToken=" + accessToken;           
                            }                 
                            // const Url = "/x-recharge-page1?accessToken=" + accessToken;                            
                            const inapp = 1;
                            const monthtopup = 1;
                            if (Url !== "") {
                                let newurl = Url;
                                let newinapp = inapp;
                                if (monthtopup === 1) {
                                    let newAccessToken = undefined;
                                    let newGameId = undefined;
                                    let newAttributionId = undefined;
                                    let pendingTransactionId = undefined;

                                    if (pendingTransactionId === undefined) {
                                        newAccessToken = cookie.load("_accessToken");
                                        newGameId = cookie.load("_gameId");
                                        newAttributionId = cookie.load("_attributionId");
                                        pendingTransactionId = cookie.load("_transactionId");
                                    }
                                    let platform = cookie.load("_platform");
                                    const saveurl = window.location.origin + `/confirm-purchase/?gameId=${newGameId}&attributionId=${newAttributionId}&transactionId=${pendingTransactionId}&accessToken=${newAccessToken}&platform=${platform}`;
                                    // console.log(saveurl);
                                    const data = await KaisakuApi.setUrlToTemp(saveurl, newAccessToken);
                                    if (data.status === 200) {
                                        newurl = newurl.replace(RegExp("@newaccessToken@", "g"), newAccessToken);
                                        newurl = newurl.replace(RegExp("download", "g"), "") + "&code=" + data.data.code;
                                        // console.log(newurl);
                                        // alert(url);
                                        window.location.href = newurl;
                                    }
                                } else {
                                    if (inapp === 1) {
                                        navigate(newurl);
                                    } else {
                                        window.location.href = newurl;
                                    }
                                }
                            }
                        }}
                        centered src={Logo3} />
                </div>)}


                {(platform !== "h5") && (<div style={{ width: "100%", textAlign: "center", marginTop: "2vh" }}>
                    <Image style={{
                        marginBottom: "2vh",
                        height: "3vh",
                        position: "absolute",
                        top: "3vh",
                        right: "3vw"
                    }}
                        onClick={() => {
                            navigate("/sdk-callback");
                        }}
                        centered src={manageicon} />
                </div>)}
            </div>)}
            {(mode === "LANDSCAPE") && (<div style={{
                width: '100%',
                height: "80px",
                position: "fixed",
                zIndex: "999999",
                top: "0",
                left: "0",
                backgroundImage: `url(${htopbg})`, backgroundSize: '100% 100%',
            }}>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <Image style={{
                        marginBottom: "2vh",
                        height: "30px",
                        position: "absolute",
                        top: "30px",
                        left: "1.5vw"
                    }}
                        onClick={onBack}
                        centered src={btnback} />
                </div>
                <Image style={{
                    marginTop: "10px",
                    height: "40px",
                }}
                    centered src={hlogo} />

                {(showTitle === 1) && (<Image style={{
                    marginTop: "0px",
                    height: "26px"
                }} centered src={title1} />)}

                {(showTitle === 2) && (<Image style={{
                    marginTop: "3px",
                    height: "18px"
                }} centered src={title2} />)}

                {(showTitle === 3) && (<Image style={{
                    marginTop: "3px",
                    height: "18px"
                }} centered src={title3} />)}

                {(showTitle === 4) && (<Image style={{
                    marginTop: "3px",
                    height: "18px"
                }} centered src={title4} />)}

                {(showTitle === 5) && (<Image style={{
                    marginTop: "3px",
                    height: "18px"
                }} centered src={title5} />)}

                {(platform !== "h5") && (<div style={{
                    position: "absolute",
                    top: "0.8vh",
                    right: "8vw",
                    width: "8vw"
                }}>
                    {/* {(isCnIP === false) && (<Image style={{
                    }}
                        onClick={() => {
                            window.location.href = "https://2yearatt3.sugar1818.net?r=download&accesstoken=" + accessToken;
                        }}
                        centered src={anniversary} />)}
                        {(isCnIP === true) && (<AdLogoSwipetoslide adItems={adItem1} accessToken={accessToken} />)} */}
                    {/* <AdLogoSwipetoslide adItems={adItem1} accessToken={accessToken} /> */}
                    <Image style={{
                    }}
                        onClick={async () => {
                            let Url = "https://d2p58o8lf64bbz.cloudfront.net/5month/index.html?accessToken=" + accessToken;
                            const Stage = process.env.Stage;
                            if (Stage === "dev"){
                                Url = "https://dok0bgyfz2ppp.cloudfront.net/5month/index.html?accessToken=" + accessToken;           
                            }                 
                            // const Url = "/x-recharge-page1?accessToken=" + accessToken;
                            const inapp = 1;
                            const monthtopup = 1;
                            if (Url !== "") {
                                let newurl = Url;
                                let newinapp = inapp;
                                if (monthtopup === 1) {
                                    let newAccessToken = undefined;
                                    let newGameId = undefined;
                                    let newAttributionId = undefined;
                                    let pendingTransactionId = undefined;

                                    if (pendingTransactionId === undefined) {
                                        newAccessToken = cookie.load("_accessToken");
                                        newGameId = cookie.load("_gameId");
                                        newAttributionId = cookie.load("_attributionId");
                                        pendingTransactionId = cookie.load("_transactionId");
                                    }
                                    let platform = cookie.load("_platform");
                                    const saveurl = window.location.origin + `/confirm-purchase/?gameId=${newGameId}&attributionId=${newAttributionId}&transactionId=${pendingTransactionId}&accessToken=${newAccessToken}&platform=${platform}`;
                                    // console.log(saveurl);
                                    const data = await KaisakuApi.setUrlToTemp(saveurl, newAccessToken);
                                    if (data.status === 200) {
                                        newurl = newurl.replace(RegExp("@newaccessToken@", "g"), newAccessToken);
                                        newurl = newurl.replace(RegExp("download", "g"), "") + "&code=" + data.data.code;
                                        // console.log(newurl);
                                        // alert(url);
                                        window.location.href = newurl;
                                    }
                                } else {
                                    if (inapp === 1) {
                                        navigate(newurl);
                                    } else {
                                        window.location.href = newurl;
                                    }
                                }
                            }
                        }}
                        centered src={Logo3} />
                </div>)}

                {(platform !== "h5") && (<div style={{ width: "100%", textAlign: "center" }}>
                    <Image style={{
                        marginBottom: "2vh",
                        height: "30px",
                        position: "absolute",
                        top: "30px",
                        right: "1.5vw"
                    }}
                        onClick={() => {
                            navigate("/sdk-callback");
                        }}
                        centered src={manageicon} />
                </div>)}
            </div>)}
        </>)
    };

}
export default NavigationBar2;