import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImgVideoSmall, setAdItem } from "../lib/ad-manage-api-lib";
import { navigate } from "gatsby";
import { Grid, Image } from "semantic-ui-react";
import cookie from "react-cookies";
import KaisakuApi from "../../controllers/kaisaku-api";

export class AdLogoSwipetoslide extends Component {
    constructor(props, context) {
        super(props, context);
    }

    async showAd(sType, Url, index, imageUrl, click, id, inapp, monthtopup) {
        // window.location.href = Url;
        // window.open(Url + "&r1=download", 'newwindow'+ sType);
        // window.location.href = Url + "&r1=download";
        // console.log(navigator.userAgent);
        // 暂时去掉ios两板功能
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
            // Url = "";
        }
        if (Url !== "") {
            // const url = Url + "&rr1=download";
            // //const uuid = Buffer.from(url).toString('base64');
            // const uuid = encodeURIComponent(Buffer.from(url).toString('base64'));
            // console.log(uuid);
            // // gameId=06ea3f8f-04fa-4b21-800d-3c2220f11eb2&attributionId=9&accessToken=aafb7f27-da9d-4198-a301-0dd8f73761d2&transactionId=e2815246-7db3-4a41-95e4-e14bb85ee2cf
            // let newurl = `/gotoad-return-purchase/?gameId=${this.state.newgameId}&attributionId=${this.state.newattributionId}&accessToken=${this.state.newaccessToken}&transactionId=${this.state.newtransactionId}&uuid=${uuid}&r1=download`;
            // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
            //     newurl = `/gotoadios/?gameId=${this.state.newgameId}&attributionId=${this.state.newattributionId}&accessToken=${this.state.newaccessToken}&transactionId=${this.state.newtransactionId}&uuid=${uuid}&r1=download`;
            //     if (url.indexOf("ios.18-jgg.com") >= 0) {
            //         newurl = url;
            //     }
            // }
            // console.log(newurl);
            // if (this.state.eventId) {
            //     setAdItem(id, this.state.eventId, sType, index, imageUrl, click);
            // }
            let newurl = Url;
            let newinapp = inapp;
            // const data = cookie.load("adlogo");
            // if (data) {
            //     const json = data;
            //     console.log("到这");
            //     console.log(json);
            //     newurl = json.adUrl;
            //     newinapp = json.inapp;
                
            // }
            if (monthtopup === 1){
                let newAccessToken = this.state.newaccessToken;
                let newGameId = this.state.newgameId;
                let newAttributionId = this.state.newattributionId;
                let pendingTransactionId = this.state.newtransactionId;

                if (pendingTransactionId === undefined){
                    newAccessToken = cookie.load("_accessToken");
                    newGameId = cookie.load("_gameId");
                    newAttributionId = cookie.load("_attributionId");
                    pendingTransactionId = cookie.load("_transactionId");
                }
                let platform = cookie.load("_platform");
                const saveurl = window.location.origin + `/confirm-purchase/?gameId=${newGameId}&attributionId=${newAttributionId}&transactionId=${pendingTransactionId}&accessToken=${newAccessToken}&platform=${platform}`;
                // console.log(saveurl);
                const data = await KaisakuApi.setUrlToTemp(saveurl, newAccessToken);
                if (data.status === 200) {
                    newurl = newurl.replace(RegExp("@newaccessToken@", "g"), newAccessToken);
                    newurl = newurl.replace(RegExp("download", "g"), "") + "&code=" + data.data.code;
                    // console.log(newurl);
                    // alert(url);
                    window.location.href = newurl;
                }
            }else{
                if (inapp === 1) {
                    navigate(newurl);
                } else {
                    window.location.href = newurl;
                }    
            }
            this.slider.slickPlay();
        }
    }

    render() {

        // console.log("2222");
        // console.log(this.state.adItems);
        const { adItems, eventId, adClass, newgameId, newattributionId, newaccessToken, newtransactionId } = this.props;
        this.state = {
            eventId: eventId,
            newgameId: newgameId,
            newattributionId: newattributionId,
            newaccessToken: newaccessToken,
            newtransactionId: newtransactionId,
            adClass: adClass,
            adItems: [],
            adItem1: {},
        };
        let showed = [];
        this.state.adItems = adItems;
        this.state.adItem1 = adItems[0];
        // console.log("111111");
        // console.log(this.state.adItems);
        const settings = {
            dots: false,
            fade: false,
            infinite: true,
            arrows: false,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 4000,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnHover: false,
            afterChange: function (index) {
                 if (adItems[index] !== undefined) {
                //     cookie.save("adlogo", JSON.stringify(adItems[index]));
                 }
            }
        };
        return (
            <div style={{
                width: '100%',
                height: "110px",
                border: "0px",
            }}>

            </div>
        );
    }
}

