import React, {Component} from "react";
import {Form} from "semantic-ui-react";
import i18next from "i18next";

export class InputField extends Component {
    render(props) {
        return (
            <Form.Input
                {...props}
            />
        );
    }
}

export class PasswordField extends InputField {
    render() {
        return super.render({
            type: "password",
            placeholder: i18next.t("SDK.Password"),
            ...this.props
        });
    }
}

export class EmailField extends InputField {
    render() {
        return super.render({
            placeholder: i18next.t("SDK.Emailaddress"),
            ...this.props
        });
    }
}

export class UsernameEmailField extends InputField {
    render() {
        return super.render({
            placeholder: i18next.t("SDK.Usernameoremailaddress"),
            ...this.props
        });
    }
}

export class UsernameField extends InputField {
    render() {
        return super.render({
            placeholder: i18next.t("SDK.Username"),
            ...this.props
        });
    }
}